<template>
  <div>
    <b class="red--text text--darken-4">
      {{ tagsData.name }}：
    </b>
    <v-chip
      v-for="(tag, index) in tagsData.value"
      :key="index"
      class="ma-2"
      color="pink"
      text-color="white"
    >
      <v-avatar left>
        <v-icon x-small>
          fa fa-bookmark
        </v-icon>
      </v-avatar>
      {{ tag }}
    </v-chip>
  </div>
</template>

<script>
  export default {
    props: {
      tagsData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
  }
</script>

<style>

</style>
